import React from "react";
import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";
import supportHero from "./../../../assets/image/rebrand/jpg/support-hero.jpg";
import { Section, Input } from "../../Core";
import SearchKnowledgeBase from "../SearchKnowledgeBase/SearchKnowledgeBase";

const StyledSection = styled(Section)`
    background: url(${supportHero}) no-repeat center center;
    background-size: cover;
`

const HeroSupport = (props) => {
    return (
        <StyledSection>
            <Container>
                <Row>
                    <Col sm={12} className="mb-80 mt-80">
                        <h1 className="green-cl">We're here to<br />help you.</h1>
                        <Row>
                            <Col sm={7}>
                                <SearchKnowledgeBase />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledSection>
    )
};

export default HeroSupport;
